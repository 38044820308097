<template>
  <b-card
    v-if="Object.keys(blogEdit).length"
    class="blog-edit-wrapper"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('User Full Name')"
            class="mb-2"
          >
            <b-form-input
              v-model="blogEdit.user_fullname"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Created Time')"
            class="mb-2"
          >
            <flat-pickr
              v-model="blogEdit.created_time"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d' }"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Title')"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="blogEdit.title"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group
            :label="$t('Category')"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <b-form-tags v-model="blogEdit.blogCategories" />
          </b-form-group>
        </b-col> -->
        <b-col md="6">
          <b-form-group
            :label="$t('Slug')"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              :value="blogSlug"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Status')"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="blogEdit.status"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              :reduce="val => val.value"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Tags')"
            class="mb-2"
          >
            <b-form-tags v-model="blogEdit.tags" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('Content')"
            label-for="blog-content"
            class="mb-2"
          >
            <quill-editor
              id="blog-content"
              v-model="blogEdit.description"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="9"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              {{ $t('Featured Image') }}
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside class="w-100 image-full-content">
                <image-input
                  style="height: 300px"
                  :default-image="blogEdit.picture"
                  :aspectRatio="`1600 x 545`"
                  @input-file="inputFile"
                />
              </b-media-aside>
            </b-media>
          </div>
        </b-col>
        <b-col
          md="3"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              {{ $t('User Image') }}
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside class="w-100">
                <image-input
                  style="height: 300px"
                  :default-image="blogEdit.user_picture"
                  @input-file="inputUserFile"
                />
              </b-media-aside>
            </b-media>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!isViewPage"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="createBlog"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="goBack"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import { BCard, BMedia, BFormTags, BMediaAside, BForm, BRow, BCol, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from '../../users/shared/ImageInput.vue'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BFormTags,
    BCard,
    BMedia,
    flatPickr,
    BMediaAside,
    ImageInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogEdit: {
        title: '',
      },
      blogFile: null,
      isViewPage: false,
      globalBlogId: null,
      categoryOption: ['Fashion', 'Food', 'Gaming', 'Quote', 'Video'],
      statusOption: ['Pending', 'Published'].map((x, i) => ({
        label: x,
        value: i,
      })),
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    blogSlug() {
      if (!this.blogEdit) return ''
      if (!this.blogEdit.title) return ''
      return this.blogEdit.title
        .toLowerCase()
        .trim()
        .replace(/ /g, '-').replace(/[^a-zA-Z- ]/g, '')
    },
  },
  created() {
    this.blogEdit = {
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      user_fullname: '',
      created_time: '',
      title: '',
      blogCategories: ['Fashion', 'Gaming'],
      slug: '',
      status: 0,
      excerpt: '',
    }
  },
  mounted() {
    this.globalBlogId = this.$route.params.editBlogId || this.$route.params.viewBlogId || null
    if (this.globalBlogId) {
      this.getBlogById()
    }
    this.isViewPage = this.$route.params.viewBlogId != null
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  methods: {
    inputFile(payload) {
      if (this.blogEdit) this.blogEdit.picture = payload
    },
    inputUserFile(payload) {
      if (this.blogEdit) this.blogEdit.user_picture = payload
    },
    createBlog() {
      const formData = new FormData()
      formData.append('title', this.blogEdit.title)
      formData.append('user_fullname', this.blogEdit.user_fullname)
      formData.append('created_time', this.blogEdit.created_time)
      formData.append('description', this.blogEdit.description)
      if (!this.blogEdit.tags) {
        this.blogEdit.tags = []
      }
      const tags = Array.isArray(this.blogEdit.tags) ? this.blogEdit.tags : this.blogEdit.tags.split(',')
      tags.forEach((x, i) => {
        formData.append(`tags[${i}]`, x)
      })
      formData.append('status', this.blogEdit.status)
      if (typeof this.blogEdit.picture !== 'string' && this.blogEdit.picture) {
        formData.append('picture', this.blogEdit.picture)
      }
      if (typeof this.blogEdit.user_picture !== 'string' && this.blogEdit.user_picture) {
        formData.append('user_picture', this.blogEdit.user_picture)
      }
      if (this.globalBlogId) {
        formData.append('id', this.globalBlogId)
        formData.append('_method', 'PATCH')
      }
      showLoader()
      const { addBlog, updateBlog } = useAdminUi()
      const payloadCaller = !this.globalBlogId ? addBlog : updateBlog
      payloadCaller({
        data: formData,
        query: this.globalBlogId ? `${this.globalBlogId}` : '',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$router.push({
              path: '/admin/cmspages/blog',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    goBack() {
      window.history.back()
    },
    getBlogById() {
      const { getBlogById } = useAdminUi()
      showLoader()
      getBlogById(this.globalBlogId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const blogDataDetail = data.responseData
            this.blogEdit.id = blogDataDetail.id
            this.blogEdit.user_fullname = blogDataDetail.user_fullname
            this.blogEdit.created_time = blogDataDetail.created_time
            this.blogEdit.tags = blogDataDetail.tags
            this.blogEdit.title = blogDataDetail.title
            this.blogEdit.status = blogDataDetail.status
            this.blogEdit.user_picture = window.SuperApp.helper.image(blogDataDetail.user_picture)
            this.blogEdit.picture = window.SuperApp.helper.image(blogDataDetail.picture)
            this.blogEdit.description = blogDataDetail.description
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.blog-edit-wrapper .image-full-content {
  .base-image-input {
    max-width: 100%;
    min-width: 100%;
  }
}
</style>
